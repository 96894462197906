<template>
  <div class="page">
    <Login ref="login" :callback="init" mode="GST" />
    <div class="search">
      <van-row>
        <van-col span="3" @click="back()"
          ><div class="back-btn">
            <van-icon name="arrow-left" />返回
          </div></van-col
        >
        <van-col span="21">
          <van-search
            v-model="keyword"
            placeholder="请输入搜索关键词"
            ref="keyword"
            show-action
            ><template #action>
              <div @click="search">搜索</div>
            </template></van-search
          >
        </van-col>
      </van-row>
    </div>
    <div class="list">
      <van-row class="title">
        <van-col span="12" class="text"
          ><van-icon name="notes-o" /> 学校列表</van-col
        >
      </van-row>
      <van-list
        :finished="finished"
        finished-text="没有更多了"
        offset="0"
        class="products"
        :immediate-check="false"
        @load="retrieveProductPage"
      >
        <template #default>
          <van-row v-for="product in productList" :key="product.code">
            <van-col @click="detail(product.code)" span="24">
              <van-row class="product">
                <van-col span="5">
                  <div class="image">
                    <van-image
                      radius="3"
                      :src="product.imagePath"
                    ></van-image></div
                ></van-col>
                <van-col span="19">
                  <van-row>
                    <van-col span="24" class="name">{{ product.name }}</van-col>
                  </van-row>
                  <van-row class="bbd">
                    <van-col span="24" class="name-en">{{
                      product.nameEn
                    }}</van-col>
                  </van-row>
                  <van-row>
                    <van-col span="6" class="tle">国家：</van-col>
                    <van-col span="6" class="content">{{
                      product.country
                    }}</van-col>
                    <van-col span="6" class="tle">城市：</van-col>
                    <van-col span="6" class="content">{{
                      product.city
                    }}</van-col>
                  </van-row>
                  <van-row class="bbd">
                    <van-col span="6" class="tle">性质：</van-col>
                    <van-col span="6" class="content"
                      ><van-tag
                        :color="COLOR_M"
                        v-if="product.quality === 'GOV'"
                        >公立</van-tag
                      ><van-tag
                        :color="COLOR_M"
                        v-if="product.quality === 'PSN'"
                        >私立</van-tag
                      ></van-col
                    >
                    <van-col span="6" class="tle">类型：</van-col>
                    <van-col span="6" class="content"
                      ><van-tag :color="COLOR_M" v-if="product.type === 'CHS'"
                        >综合</van-tag
                      ></van-col
                    >
                  </van-row>
                  <van-row>
                    <van-col span="6" class="tle">USNEWS：</van-col>
                    <van-col span="6" class="content color-s1">{{
                      product.usnews
                    }}</van-col>
                    <van-col span="6" class="tle">泰晤士：</van-col>
                    <van-col span="6" class="content color-s1">{{
                      product.thames
                    }}</van-col>
                  </van-row>
                </van-col>
              </van-row>
            </van-col>
          </van-row>
          <van-row v-if="productList.length <= 0">
            <van-col span="24" class="no-record">
              <van-image
                width="103"
                height="103"
                :src="require('../../../../assets/images/home/no-record.png')"
              ></van-image>
            </van-col>
          </van-row>
        </template>
      </van-list>
    </div>
    <Clue ref="clue" />
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <LogVisit ref="logVisit" module-type="STD" module="STD_SEARCH_INDEX" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
